// Icons
//@import '~simple-line-icons/css/simple-line-icons.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'flag-icon.min.css';

// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

// Import all of CoreUI's CSS
@import "@coreui/coreui/scss/coreui";

@import "theme";

@import "custom";

// @import '~@fortawesome/fontawesome-free/css/all.min.css';

// // Coreui
// @import '~@coreui/coreui/dist/css/coreui.min.css';
// @import '~@coreui/icons/css/all.css';

// // Datatables
// @import '~datatables.net-bs4/css/dataTables.bootstrap4.min.css';
// @import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';

// // Cropper JS
// @import '~cropperjs/dist/cropper.min.css';

// // Select2
// @import '~select2/dist/css/select2.min.css';
// @import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

// // Toastr
// @import "~toastr";


// @import '~jstree/dist/themes/default/style';
