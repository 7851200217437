// Here you can add other styles
.close {
    position: absolute;
    top: 0;
    padding: .75rem 1.25rem;
    color: inherit;
    right: 0;
    background-color: transparent;
    border: 0;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    opacity: 1;
    text-shadow: 0 1px 0 #fff;
}

.form-group {
    margin-bottom: 1rem;
}

.color-square {
    width: 25px;
    height: 25px;
    background-color: #e3e3e3;
    display: block;
}

.dataTables_length, .dataTables_filter {
    label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    select, input {
        width: auto;
    }
}

.main_employee_1, .main_employee_0 {
    &:after {
        content: "S";
        position: relative;
        margin-left: 10px;
        line-height: 140%;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 10px;
    }
}


.main_employee_1 {
    &:after {
        content: 'M';
        background-color: #fbd74a;
    }
}

.main_employee_0 {
    &:after {
        content: 'S';
        background-color: #dddddd;
    }
}

.sidebar-nav .nav-icon {
    font-size: .8rem;
    padding-top: 4px;
    padding-right: 10px;
}


.bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}


.ac-disabled {
    pointer-events: none;
    background-color: #cacaca;
}
.ac-examweek {
    background-color: #e5cbcb;
}
.ac-set {
    background-color: #cbe5cb;
}


#availabilityList {
    list-style: none;
    padding-left: 0px;
    li {
        background-color: #d6d6d6;
        padding: .5rem 1rem;
        margin-bottom: .5rem;
        border: 1px solid #8a8a8a;
        border-radius: 5px;

        .date {
            font-weight: 700;
            font-size: 1.1rem;
            margin-bottom: 5px;
        }
        .custom-checkbox {
            display: inline-block;
            margin: 0px 10px 0px 0px;
            border: 1px solid #a6a6a6;
            padding: 7px;
            border-radius: 5px;
            background-color: rgba(0,0,0,.05);
            transition: all .7s;
            cursor: pointer;

            input {
                width: 17px;
                height: 17px;
            }
            label {
                cursor: pointer;
            }

            &:hover {
                background-color: rgba(0,0,0,0.15);
            }
        }

        &:nth-of-type(even) {
            background-color: #f2f2f2;
        }
    }
}



#beslistboom {
    .parts {
        .question-container {
            border: 1px solid #e3e3e3;
            background-color: #fff;
            color: #000;
            border-radius: 15px;
            padding: 1.5rem 2rem;

            .question {
                margin-bottom: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
            }

            .answer {
                .yes {
                    background-color: #2f95b3;
                    border: 1px solid #2f95b3;
                    padding: .2rem 1.5rem;
                    font-size: 1.2rem;
                }
                .no {
                    background-color: #ab0534;
                    border: 1px solid #ab0534;
                    padding: .2rem 1.5rem;
                    font-size: 1.2rem;
                }

                .message {
                    background-color: #2f95b3;
                    border: 1px solid #2f95b3;
                    padding: 0.5rem 1.5rem;
                    font-size: 1.2rem;
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                    display: block;
                }

                table {
                    margin: 1rem 0px;
                    tr {
                        border: 1px solid black;

                        td {
                            border: 1px solid black;
                            padding: .5rem;
                        }

                        th {
                            border: 1px solid black;
                            padding: .5rem;
                        }
                    }
                }
            }
        }

        .no-answer  {
            background-color: #ab0534;
            color: #ffffff;
            border-color: #420214;
        }

        .message-ja {
            background-color: #2f95b3;
            color: #ffffff;
            border-color: #1a5668;
        }

        .message-nee {
            background-color: #ab0534;
            color: #ffffff;
            border-color: #420214;
        }

        .message-onbekend {
            background-color: #F7B538;
            color: #000000;
            border-color: #b8862b;
        }
    }
}
